import ResetPassEmailForm from 'components/SigninForm/ResetPassEmailForm';
import React from 'react';

const ConfirmEmailPage = () => {
  return (
    <div>
      <ResetPassEmailForm />
    </div>
  );
};

export default ConfirmEmailPage;
